<template>
  <div>
    <!-- 基本信息卡片区域 -->
    <a-card class="card" title="基本信息" :bordered="false">
      <!-- 操作按钮区域 -->
      <div slot="extra">
        <a-button :disabled="!isEdit" @click="handleExport">代销单</a-button>
        <a-divider type="vertical" />
        <a-button>
          <router-link :to="{ name: 'WmsAgent' }">取消</router-link>
        </a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
      </div>

      <!-- 基本信息表单区域 -->
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="代销单号">
              <a-input placeholder="代销单号自动生成" disabled v-model="form.agent_num" />
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item label="代销时间" prop="agent_date">
              <a-date-picker style="width: 100%" format="YYYY/MM/DD HH:mm:ss" v-model="form.agent_date" />
            </a-form-model-item>
          </a-col>

          <a-col :span="12">
            <a-form-model-item label="购买单位" prop="buyer">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="订单类型">
              <a-select style="width: 100%" v-model="form.order_type" @change="orderTypeChange">
                <a-select-option :value="1">普通</a-select-option>
                <a-select-option :value="2">团购</a-select-option>
                <a-select-option :value="3">工会</a-select-option>
                <a-select-option :value="4">内购</a-select-option>
                <a-select-option :value="5">饭卡</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item label="代理费">
              <a-input-number :disabled="form.lock_agent_fee" style="width: 100%" v-model="form.agency_fee" />
            </a-form-model-item>
          </a-col>

          <a-col :span="12">
            <a-form-model-item label="收货地址">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择收货地址"
                style="width: 100%"
                :value="receivingAddressInfo"
                :filter-option="false"
                :not-found-content="fetching2 ? undefined : null"
                @search="getReceivingAddress"
                @change="changeReceivingAddress"
              >
                <template v-if="fetching2" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in receivingAddressOps" :key="op.id">{{ op.receiving_address + '/' + op.receiving_person + '/' + op.phone }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="收费类型">
              <a-select :disabled="form.lock_charge_type" style="width: 100%" v-model="form.charge_type">
                <a-select-option :value="1">现金</a-select-option>
                <a-select-option :value="2">月结</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item label="订购人">
              <a-input v-model="form.order_person" placeholder="请输入订购人" />
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item label="订购电话">
              <a-input v-model="form.order_phone" placeholder="请输入订购电话" />
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item label="推荐人">
              <a-input v-model="form.recommender" placeholder="请输入推荐人" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="送货方式">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择送货方式"
                style="width: 100%"
                :value="escortModeInfo"
                :filter-option="false"
                :not-found-content="fetching3 ? undefined : null"
                @search="getEscortMode"
                @change="changeEscortMode"
              >
                <template v-if="fetching3" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in escortModeOps" :key="op.id">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="最晚送货时间">
              <a-date-picker style="width: 100%" show-time format="YYYY/MM/DD HH:mm:ss" v-model="form.latest_delivery_time" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-textarea v-model="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <!-- 快递信息卡片区域 -->
    <a-card class="card" title="快递信息" :bordered="false" v-if="isShowCourierInfo">
      <edit-table
        :columns="courierInfoColumns"
        :sourceData="courierInfoData"
        :scroll="true"
        :operate="{ add: true, del: true, alldel: true }"
        @getTableDate="getCourierInfoData"
        @getChangeData="getCourierChangeData"
      ></edit-table>
    </a-card>

    <!-- 其它信息卡片区域 -->
    <a-card class="card" title="其它信息" :bordered="false">
      <a-tabs
        :activeKey="activeKey"
        @change="
          key => {
            activeKey = key
          }
        "
      >
        <!-- 货物信息 table 表格 -->
        <a-tab-pane tab="货物信息" key="1">
          <edit-table :columns="goodsColumns" :sourceData="goodsData" :scroll="true" @getTableDate="getGoodsData" />
        </a-tab-pane>

        <!-- 现金收费按钮 -->
        <template slot="tabBarExtraContent">
          <a-button type="primary" v-if="activeKey === '1'" :disabled="!isEdit" @click="getPayQrcode">现金收费</a-button>
          <a-button type="primary" v-if="activeKey === '1'" @click="refundApply" style="margin-left: 20px;">退款申请</a-button>
          <span style="margin:0px 30px;">出货总价：{{ price_out_total.toFixed(2) }}</span>
        </template>
      </a-tabs>
    </a-card>

    <!-- 现金收费记录 -->
    <a-modal
      :visible="cashRecordVisible"
      :confirmLoading="cashRecordLoading"
      :width="1520"
      :maskClosable="false"
      title="现金收费记录"
      @cancel="cashRecordVisible = false"
      :footer="null"
    >
      <a-card style="margin-bottom: 10px">
        <a-radio-group name="radioGroup" v-model="cashType">
          <a-radio :value="1">微信</a-radio>
          <a-radio :value="2">支付宝</a-radio>
        </a-radio-group>
        <a-button type="primary" @click="createCashRecord">
          创建收款码
        </a-button>
      </a-card>
      <a-table :columns="cashColumns" :data-source="cashRecord" :pagination="false" row-key="id" size="small">
        <div slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </div>
        <div slot="pay_info" slot-scope="text, item">
          <img :src="item.pay_info" v-if="item.pay_info && item.is_expire === 0" style="width: 200px; height: 200px;border: 1px solid #f1f1f1">
          <img src="~@/assets/invalid_code.jpg" v-else style="width: 200px; height: 200px;border: 1px solid #f1f1f1">
        </div>
        <div slot="good_info" slot-scope="text">
          <div v-if="text">
            <span style="display:block" v-for="item in text" :key="item.id">{{ `${item.good_name}${item.good_spec} ${item.num}${item.good_unit} 合计：${item.charge}` }}</span>
          </div>
        </div>
        <div slot="action" slot-scope="text, item">
          <a v-if="item.cash_status === 0" @click="delCashRecord(item)">删除</a>
          <a v-if="item.cash_status === 1" @click="handleRefundCashRecord(item)">退款</a>
        </div>
      </a-table>
    </a-modal>

    <!-- 支付二维码弹出框区域 -->
    <pay-qrcode
      :visible.sync="payQrcodeVisible"
      v-if="getQrcodeParams.id"
      :getQrcodeFun="getAgentQrcode"
      :params="getQrcodeParams"
    ></pay-qrcode>

    <!-- 退款申请 -->
    <a-modal
      :visible="refundApplyVisible"
      :confirmLoading="refundApplyLoading"
      :width="1350"
      :maskClosable="false"
      title="退款申请"
      @ok="refundApplySubmit"
      @cancel="refundApplyCancel"
    >
      <a-form-model
        layout="vertical"
        ref="refundApplyForm"
        :model="refundApplyForm"
        :rules="refundApplyRule"
      >
        <a-row :gutter="24">
          <a-divider orientation="left">退款总金额
            <a-tag color="#f50" style="margin-left: 10px;">{{ refundAmountTotal.toFixed(2) }}</a-tag>
          </a-divider>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left">商品信息</a-divider>
          <edit-table
            :columns="refundApplyColumns"
            :sourceData="refundApplyData"
            @getTableDate="getRefundApplyData"
            :disabled="true"
          ></edit-table>
        </a-row>
        <a-divider orientation="left">其他信息</a-divider>
        <!-- 当订单产生了代理费、退款时才可以选择是否退代理费 -->
        <a-row :gutter="24" v-if="form.agency_fee > 0">
          <a-col :span="12">
            <a-form-model-item label="代理费是否退款">
              <a-select
                v-model="refundApplyForm.is_agent_fee_refund"
                style="width: 100%"
                allowClear
                @change="isAgentFeeRefundChange"
              >
                <a-select-option v-for="op in isAgentFeeRefundData" :key="op.value">
                  {{ op.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" v-if="refundApplyForm.is_agent_fee_refund === 1">
            <a-form-model-item label="代理费退款金额">
              <a-input v-model="refundApplyForm.refund_agent_fee" disabled/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="申请退款原因" prop="apply_refund_reason">
              <a-textarea v-model="refundApplyForm.apply_refund_reason" allowClear />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { createAgent,
getAgentGoodsList,
getAllReceivingAddress,
getGoodInfoList,
getAllEscortMode,
listPayDealGoodInfo,
agentRefundApply
} from '@/api/wms/sale'
import {
getAgentQrcode,
billCashRecord,
delCashDeal,
refundCashDeal,
createCashQRCode,
exportWmsAgent
} from '@/api/wms'
import moment from 'moment'
import { getFirstLetter } from '@/utils/util'
import { EditTable } from '@/components'
import preventReClick from '@/utils/preventReClick'
import {
  getCustomer,
  getSupplier,
  downloadExcel
} from '@/api/common'
import debounce from 'lodash/debounce'
import PayQrcode from '@/views/components/PayQrcode'

export default {
  components: {
    EditTable,
    preventReClick,
    PayQrcode
  },
  data() {
    this.lastFetchId = 0;
    this.lastFetchId2 = 0;
    this.lastFetchId3 = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.getReceivingAddress = debounce(this.getReceivingAddress, 800);
    this.getEscortMode = debounce(this.getEscortMode, 800)
    return {
      confirmLoading: false,
      isEdit: false,
      activeKey: '1',
      form: {
        agent_num: '',
        agent_date: null,
        buyer: null,
        remark: '',
        // supplier_id: null,
        agency_fee: 0,
        order_type: 1,
        charge_type: 2
      },
      rules: {
        agent_date: [{ required: true, message: '请选择代销时间', trigger: 'change' }],
        // supplier_id: [{ required: true, message: '请选择供应商', trigger: 'blur' }],
        buyer: [{ required: true, message: '请选择购买单位', trigger: 'blur' }]
      },
      buyerOps: [], // 购买单位
      supplierOps: [], // 供应商
      receivingAddressOps: [], // 收货地址
      escortModeOps: [], // 送货方式
      customerInfo: undefined,
      receivingAddressInfo: undefined,
      escortModeInfo: undefined,
      fetching: false,
      fetching2: false,
      fetching3: false,
      goodsData: [],
      collectFeeData: [],
      feeData: [],
      goodInfoList: [],
      goodNoOps: [],
      goodsColumns: [
        {
          title: `货物编码`,
          dataIndex: `good_no`,
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.change_status === 1 || record.pay_status === 1}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null;
                  for (const item of this.goodInfoList) {
                    if (item.id === val) {
                      value = item;
                    }
                  }
                  this.goodsData[index]['good_id'] = value.id;
                  this.goodsData[index]['good_no'] = value.good_no;
                  this.goodsData[index]['good_name'] = value.good_name;
                  this.goodsData[index]['good_unit'] = value.good_unit;
                  this.goodsData[index]['good_spec'] = value.good_spec;
                  // this.goodsData[index]['price_in'] = value.price_in;
                  const priceInData = [];
                  const priceOutData = [];
                  value.price_in.forEach(v => priceInData.push({ name: v, value: v }));
                  value.price_out.forEach(v => priceOutData.push({ name: v, value: v }));
                  this.goodsColumns[4].extra.options = priceInData;
                  this.goodsColumns[5].extra.options = priceOutData;
                  this.goodsColumns = [...this.goodsColumns];
                }}
              >
                {this.goodNoOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          width: 500,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.change_status === 1 || record.pay_status === 1}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null
                  for (const item of this.goodInfoList) {
                    if (item.id === val) {
                      value = item
                    }
                  }
                  this.goodsData[index]['good_id'] = value.id;
                  this.goodsData[index]['good_no'] = value.good_no;
                  this.goodsData[index]['good_name'] = value.good_name;
                  this.goodsData[index]['good_unit'] = value.good_unit;
                  this.goodsData[index]['good_spec'] = value.good_spec;
                  // this.goodsData[index]['price_in'] = value.price_in;
                  const priceInData = [];
                  const priceOutData = [];
                  value.price_in.forEach(v => priceInData.push({ name: v, value: v }));
                  value.price_out.forEach(v => priceOutData.push({ name: v, value: v }));
                  this.goodsColumns[4].extra.options = priceInData;
                  this.goodsColumns[5].extra.options = priceOutData;
                  this.goodsColumns = [...this.goodsColumns];
                }}
              >
                {this.goodInfoOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit',
          width: 80,
          extra: {
            type: 'text',
            disabled: record => {
              return record.change_status === 1 || record.pay_status === 1
            }
          }
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          width: 80,
          extra: {
            type: 'text',
            disabled: record => {
              return record.change_status === 1 || record.pay_status === 1
            }
          }
        },
        {
          title: '进货单价',
          dataIndex: 'price_in',
          width: 120,
          extra: {
            type: 'select',
            options: [],
            disabled: record => {
              return record.pay_status === 1
            }
          }
        },
        {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 120,
          extra: {
            type: `select`,
            options: [],
            extraFunc: this.calcTotalPrice,
            disabled: record => {
              return record.change_status === 1
            }
          }
        },
        {
          title: '出货数量',
          dataIndex: 'num',
          width: 110,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width: 100%"
                value={text}
                disabled={record.change_status === 1 || record.pay_status === 1}
                min={0}
                onChange={val => {
                  this.goodsData[index]['num'] = val
                  if (val && this.goodsData[index]['price_out']) {
                    this.goodsData[index]['charge_total_price'] = (val * this.goodsData[index]['price_out']).toFixed(2)
                  } else {
                    this.goodsData[index]['charge_total_price'] = 0
                  }
                  let money = 0;
                  this.goodsData.forEach(v => {
                    money += parseFloat(v.charge_total_price);
                  });
                  this.price_out_total = money;
                }}
              />
            )
          }
        },
        {
          title: '出货总价',
          dataIndex: 'charge_total_price',
          width: 120,
          extra: {
            type: `text`,
            disabled: record => {
              return record.change_status === 1 || record.pay_status === 1
            }
          }
        },
        // {
        //   title: '结算方式',
        //   dataIndex: 'charge_type',
        //   width: 120,
        //   extra: {
        //     defaultValue: 1,
        //     disabled: record => {
        //       return record.status === 1
        //     },
        //     type: 'select',
        //     options: [
        //       {
        //         name: '现金',
        //         value: 1
        //       },
        //       {
        //         name: '月结',
        //         value: 2
        //       }
        //     ]
        //   }
        // },
        {
          title: '交易号',
          dataIndex: 'trx_num',
          width: 120,
          extra: {
            type: 'text',
            disabled: record => {
              return true
            }
          }
        },
        {
          title: `交易时间`,
          dataIndex: `pay_time`,
          width: 150,
          extra: {
            type: `text`,
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '供应商',
          dataIndex: 'supplier_id',
          width: 220,
          extra: {
            type: 'select',
            func: getSupplier,
            params: [ { department: 8 } ],
            options: []
            // disabled: record => {
            //   return record.change_status === 1 || record.pay_status === 1
            // }
          }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 120,
          extra: {
            disabled: record => {
              return record.change_status === 1 || record.pay_status === 1
            }
          }
        }
      ],
      payQrcodeVisible: false,
      getQrcodeParams: {},
      price_out_total: 0,
      cashRecordVisible: false,
      cashRecordLoading: false,
      cashRecord: [],
      cashType: 1,
      cashColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '二维码',
          dataIndex: 'pay_info',
          scopedSlots: { customRender: 'pay_info' }
        },
        {
          title: '收费类型',
          dataIndex: 'deal_type',
          customRender: (text) => {
            if (text === 0) {
              return '未知'
            }
            if (text === 1) {
              return '微信'
            }
            if (text === 2) {
              return '支付宝'
            }
          }
        },
        {
          title: '交易状态',
          dataIndex: 'cash_status',
          customRender: (text, record) => {
            if (text === 1) {
              return '已完成'
            }
            if (text === 0) {
              if (record.is_expire === 1) {
                return '已过期'
              } else {
                return '待支付'
              }
            }
          }
        },
        {
          title: '货物信息',
          dataIndex: 'good_out_list',
          scopedSlots: { customRender: 'good_info' }
        },
        {
          title: '金额',
          dataIndex: 'amount'
        },
        {
          title: '交易号',
          dataIndex: 'trx_id'
        },
        {
          title: '完成时间',
          dataIndex: 'pay_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 是否显示快递信息输入框
      isShowCourierInfo: false,
      courierInfoColumns: [
        {
          title: '快递单号',
          width: 350,
          dataIndex: 'courier_no',
          extra: { type: 'input' }
        },
         {
          title: '发货日期',
          width: 150,
          dataIndex: 'send_good_date',
          extra: {
            type: 'date',
            default: null
          }
        },
        {
          title: '包裹数量',
          dataIndex: 'package_num',
          extra: { type: 'number' }
        },
        {
          title: '计费重量',
          dataIndex: 'weight',
          extra: { type: 'number' }
        },
        {
          title: '快递费',
          dataIndex: 'courier_fee',
          extra: { type: 'number' }
        },
        {
          title: '包装费',
          dataIndex: 'package_fee',
          extra: { type: 'number' }
        },
        {
          title: '保险费',
          dataIndex: 'safe_fee',
          extra: { type: 'number' }
        },
        {
          title: '资源调节费',
          dataIndex: 'resource_fee',
          extra: { type: 'number' }
        }
      ],
      courierInfoData: [],
      refundApplyVisible: false,
      refundApplyLoading: false,
      refundApplyColumns: [
        {
          title: '交易号',
          dataIndex: 'trx_id',
          width: 120,
          extra: {
            type: 'text'
          }
        },
        {
          title: '交易时间',
          dataIndex: 'pay_date',
          width: 120,
          extra: {
            type: 'text'
          }
        },
        {
          title: '交易状态',
          dataIndex: 'cash_status',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物信息',
          dataIndex: 'good_info',
          width: 200,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物卖出价格',
          dataIndex: 'price_out',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物数量(已支付)',
          dataIndex: 'good_num',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物数量(剩余可退款)',
          dataIndex: 'after_refund_num',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '退款数量',
          dataIndex: 'refund_num',
          width: 80,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width: 100%"
                value={text}
                min={0}
                onChange={val => {
                  this.refundApplyData[index]['refund_num'] = val
                  if (val > this.refundApplyData[index]['after_refund_num']) {
                    this.$notification['error']({
                      message: '提示',
                      description: '要退款的数量不能超过剩余可退数量'
                    })
                  } else {
                    if (val && this.refundApplyData[index]['price_out']) {
                      this.refundApplyData[index]['refund_price'] = (val * this.refundApplyData[index]['price_out']).toFixed(2)
                    } else {
                      this.refundApplyData[index]['refund_price'] = 0
                    }
                    let money = 0
                    this.refundApplyData.forEach(v => {
                      money += parseFloat(v.refund_price)
                    })
                    this.refundAmountTotal = money
                  }
                }}
              ></a-input-number>
            )
          }
        },
        {
          title: '退款总价',
          dataIndex: 'refund_price',
          width: 80,
          extra: {
            type: 'text'
          }
        }
      ],
      refundApplyData: [],
      // 退款总金额
      refundAmountTotal: 0,
      refundApplyForm: {
        is_agent_fee_refund: 0,
        refund_agent_fee: 0,
        apply_refund_reason: null
      },
      refundApplyRule: {
        apply_refund_reason: [{ required: true, message: '请填写申请退款原因', trigger: 'click' }]
      },
      // 代理费是否退款
      isAgentFeeRefundData: [
        { value: 0, name: '否' },
        { value: 1, name: '是' }
      ]
    }
  },
  watch: {
    $route: function(newRoute) {
      if (!this.queryParam && newRoute.name === 'AgentCUR') {
        this.handleFormReset()
        if (newRoute.params.id === 'create') {
          // 新增
          this.isEdit = false
          this.$route.meta.title = '新增代销信息'
        } else {
          this.isEdit = true
          this.$route.meta.title = '编辑代销信息'
          const outDate = newRoute.params.agent_date ? moment(newRoute.params.agent_date, 'YYYY/MM/DD HH:mm:ss') : null
          this.form = { ...newRoute.params, agent_date: outDate }
          this.customerInfo = {
            key: newRoute.params.buyer,
            label: newRoute.params.buyer_name
          }
          if (newRoute.params.receiving_address) {
            this.receivingAddressInfo = {
              key: newRoute.params.receiving_address,
              label: newRoute.params.receiving_address_name + '/' + newRoute.params.receiving_person + '/' + newRoute.params.receiving_phone
            }
          }
          if (newRoute.params.escort_mode) {
            this.escortModeInfo = {
              key: newRoute.params.escort_mode,
              label: newRoute.params.escort_mode_name
            }
          }
          // if (newRoute.params.order_type && (newRoute.params.order_type === 5 || newRoute.params.order_type === 3)) {
          //   this.goodsColumns[5] = {
          //     title: '出货单价',
          //     dataIndex: 'price_out',
          //     width: 120,
          //     extra: {
          //       type: `input-number`,
          //       disabled: record => {
          //         return record.change_status === 1
          //       }
          //     }
          //   }
          // } else {
          //   this.goodsColumns[5] = {
          //     title: '出货单价',
          //     dataIndex: 'price_out',
          //     width: 120,
          //     extra: {
          //       type: `select`,
          //       options: [],
          //       extraFunc: this.calcTotalPrice,
          //       disabled: record => {
          //         return record.change_status === 1
          //       }
          //     }
          //   }
          // }
          this.goodsColumns[5] = {
              title: '出货单价',
              dataIndex: 'price_out',
              width: 120,
              extra: {
                type: `select`,
                options: [],
                extraFunc: this.calcTotalPrice,
                disabled: record => {
                  return record.change_status === 1
                }
              }
            }
          if (newRoute.params.escort_mode_name && newRoute.params.escort_mode_name.indexOf('快递') !== -1) {
            this.isShowCourierInfo = true;
          } else {
            this.isShowCourierInfo = false;
          }
          this.getSellOtherInfo(newRoute.params.id)
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.isEdit = true
      this.$route.meta.title = '编辑代销信息'
      const outDate = this.$route.params.agent_date
        ? moment(this.$route.params.agent_date, 'YYYY/MM/DD HH:mm:ss')
        : null
      this.form = { ...this.$route.params, agent_date: outDate }
      this.customerInfo = {
        key: this.$route.params.buyer,
        label: this.$route.params.buyer_name
      }
      if (this.$route.params.receiving_address) {
        this.receivingAddressInfo = {
          key: this.$route.params.receiving_address,
          label: this.$route.params.receiving_address_name + '/' + this.$route.params.receiving_person + '/' + this.$route.params.receiving_phone
        }
      }
      if (this.$route.params.escort_mode) {
        this.escortModeInfo = {
          key: this.$route.params.escort_mode,
          label: this.$route.params.escort_mode_name
        }
      }
      // if (this.$route.params.order_type && (this.$route.params.order_type === 5 || this.$route.params.order_type === 3)) {
      //   this.goodsColumns[5] = {
      //     title: '出货单价',
      //     dataIndex: 'price_out',
      //     width: 120,
      //     extra: {
      //       type: `input-number`,
      //       disabled: record => {
      //         return record.change_status === 1
      //       }
      //     }
      //   }
      // } else {
      //   this.goodsColumns[5] = {
      //     title: '出货单价',
      //     dataIndex: 'price_out',
      //     width: 120,
      //     extra: {
      //       type: `select`,
      //       options: [],
      //       extraFunc: this.calcTotalPrice,
      //       disabled: record => {
      //         return record.change_status === 1
      //       }
      //     }
      //   }
      // }
      this.goodsColumns[5] = {
          title: '出货单价',
          dataIndex: 'price_out',
          width: 120,
          extra: {
            type: `select`,
            options: [],
            extraFunc: this.calcTotalPrice,
            disabled: record => {
              return record.change_status === 1
            }
          }
        }
      if (this.$route.params.escort_mode_name && this.$route.params.escort_mode_name.indexOf('快递') !== -1) {
        this.isShowCourierInfo = true;
      } else {
        this.isShowCourierInfo = false;
      }
      this.getSellOtherInfo(this.$route.params.id)
    }
  },
  created() {
    this.handleFormReset()
    getGoodInfoList().then(res => {
      this.goodInfoList = res
      this.goodInfoOps = res.map(e => {
        return {
          name: `${e.good_name}/${e.good_unit}/${e.good_spec}`,
          value: e.id
        }
      });
      this.goodNoOps = res.filter(e => e.good_no && e.good_no !== '').map(e => {
        return {
          name: e.good_no,
          value: e.id
        }
      })
    });
    getSupplier({ department: 8, excludeBlack: 'Y' }).then(res => {
      this.supplierOps = res
    });
  },
  methods: {
    // 退款申请
    refundApply() {
      const reqParam = {
        'order_id': this.form.id,
        'order_type': 2
      }
      listPayDealGoodInfo(reqParam).then(res => {
        if (res.length > 0) {
          this.refundApplyData = res
          this.refundApplyVisible = true
        } else {
          this.$notification['warning']({
            message: '提示',
            description: '该订单未支付或货物已全部退款'
          })
        }
      })
    },
    refundApplyCancel() {
      this.refundApplyVisible = false
      this.refundApplyLoading = false
      this.refundApplyData = []
      this.refundAmountTotal = 0
      this.refundApplyForm = {
        is_agent_fee_refund: 0,
        refund_agent_fee: 0,
        apply_refund_reason: null
      }
    },
    refundApplySubmit() {
      this.refundApplyLoading = true
      this.$refs.refundApplyForm.validate(valid => {
        if (valid) {
          const refundOrderData = []
          this.refundApplyData.forEach(item => {
            if (item.refund_num > 0) {
              refundOrderData.push({
                'good_id': item.good_id,
                'good_num': item.refund_num
              })
            }
          })
          const reqParam = {
            'order_id': this.form.id,
            'apply_order_list': refundOrderData,
            'apply_refund_amount': this.refundAmountTotal,
            'apply_refund_reason': this.refundApplyForm.apply_refund_reason,
            'is_agent_fee_refund': this.refundApplyForm.is_agent_fee_refund
          }
          agentRefundApply(reqParam).then(res => {
            if (res) {
              this.$notification['success']({
                message: '提示',
                description: '退款申请创建成功'
              })
            } else {
              this.$notification['error']({
                message: '提示',
                description: '退款申请创建失败'
              })
            }
          }).finally(_ => {
            this.refundApplyCancel()
          })
        } else {
          this.refundApplyLoading = false
        }
      })
    },
    getRefundApplyData(data) {
      this.refundApplyData = data
    },
    // 代理费是否退款
    isAgentFeeRefundChange() {
      if (this.refundApplyForm.is_agent_fee_refund === 1) {
        this.refundApplyForm.refund_agent_fee = this.form.agency_fee
        if (this.refundApplyForm.refund_agent_fee > 0) {
          this.refundAmountTotal = parseFloat(this.refundAmountTotal + this.refundApplyForm.refund_agent_fee)
        }
      } else {
        this.refundAmountTotal = 0
      }
    },
    getCourierChangeData(data) {
      if (data.column === 'send_good_date') {
        this.$set(this.courierInfoData, data.index, {
          ...this.courierInfoData[data.index],
          'send_good_date': data.value
        })
      }
    },
    getCourierInfoData(data) {
      this.courierInfoData = data;
    },
    getEscortMode(value) {
      this.lastFetchId3 += 1;
      const fetchId = this.lastFetchId3;
      this.escortModeOps = [];
      this.fetching3 = true;
      getAllEscortMode({
        name: value
      }).then(res => {
        if (fetchId !== this.lastFetchId3) {
          return
        }
        this.escortModeOps = res;
        this.fetching3 = false;
      });
    },
    changeEscortMode(value) {
      if (value && value.label.indexOf('快递') !== -1) {
        // 送货方式包含了快递
        this.isShowCourierInfo = true;
      } else {
        this.isShowCourierInfo = false;
      }
      if (value) {
        this.escortModeInfo = value;
        this.form['escort_mode'] = value.key;
        this.form['escort_mode_name'] = value.label;
      } else {
        this.escortModeInfo = undefined;
        this.form['escort_mode'] = null;
        this.form['escort_mode_name'] = null;
      }
      this.escortModeOps = [];
      this.fetching3 = false;
    },
    orderTypeChange() {
      // if (this.form.order_type === 5 || this.form.order_type === 3) {
      //   // 订单类型选择饭卡将出货单价改为填写
      //   this.goodsColumns[5] = {
      //     title: '出货单价',
      //     dataIndex: 'price_out',
      //     width: 120,
      //     extra: {
      //       type: `input-number`,
      //       disabled: record => {
      //         return record.change_status === 1
      //       }
      //     }
      //   }
      // } else {
      //   // 订单类型选择其他将出货单价改为下拉
      //   this.goodsColumns[5] = {
      //     title: '出货单价',
      //     dataIndex: 'price_out',
      //     width: 120,
      //     extra: {
      //       type: `select`,
      //       options: [],
      //       extraFunc: this.calcTotalPrice,
      //       disabled: record => {
      //         return record.change_status === 1
      //       }
      //     }
      //   }
      // }
    },
    getReceivingAddress(value) {
      this.lastFetchId2 += 1;
      const fetchId = this.lastFetchId2;
      this.receivingAddressOps = [];
      this.fetching2 = true;
      getAllReceivingAddress({
        value: value
      }).then(res => {
        if (fetchId !== this.lastFetchId2) {
          return
        }
        this.receivingAddressOps = res;
        this.fetching2 = false;
      });
    },
    changeReceivingAddress(value) {
      if (value !== undefined) {
        this.receivingAddressInfo = value;
        this.form['receiving_address'] = value.key;
        this.form['receiving_address_name'] = value.label;
      } else {
        this.receivingAddressInfo = undefined;
        this.form['receiving_address'] = 0;
        this.form['receiving_address_name'] = null;
      }
      this.receivingAddressOps = [];
      this.fetching2 = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    moment,
    getAgentQrcode,
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.buyerOps = []
      this.fetching = true
      getCustomer({
        department: 8,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.buyerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['buyer'] = value.key
      this.form['buyer_name'] = value.label
      if (!this.form.lock_charge_type) {
        if (value.label === '个人') {
          this.form.charge_type = 1;
        } else {
          this.form.charge_type = 2;
        }
      }
      this.buyerOps = []
      this.fetching = false
    },
    handleFormReset() {
      this.form = {
        sell_num: '',
        agent_date: null,
        buyer: null,
        remark: '',
        // supplier_id: null,
        agency_fee: 0,
        order_type: 1,
        charge_type: 2
      }
      this.goodsData = []
      this.collectFeeData = []
      this.feeData = []
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      });
      this.price_out_total = 0;
      this.escortModeInfo = undefined;
      this.courierInfoData = [];
    },
    getSellOtherInfo(id) {
      // 查询货物信息
      getAgentGoodsList(id).then(v => {
        this.form = v.agent;
        this.goodsData = v.good_agent_list;
        this.price_out_total = 0;
        this.goodsData.forEach(v => {
          if (v.change_status === 1 || v.pay_status === 1) v.disabled = true;
          this.price_out_total += parseFloat(v.charge_total_price);
        });
        this.courierInfoData = v.agent.courier_info_list
      })
    },
    getGoodsData(data) {
      this.goodsData = data;
      let money = 0;
      this.goodsData.forEach(v => {
        money += parseFloat(v.charge_total_price);
      });
      this.price_out_total = money;
    },
    getCollectFeeData(data) {
      this.collectFeeData = data
    },
    getFeeData(data) {
      this.feeData = data
    },
    calcTotalPrice(value, newData, target) {
      if (target.price_out && target.num) {
        target['charge_total_price'] = (target.price_out * target.num).toFixed(2)
      } else {
        target['charge_total_price'] = 0
      }
      let money = 0;
      this.goodsData.forEach(v => {
        money += parseFloat(v.charge_total_price);
      });
      this.price_out_total = money;
      return newData
    },
    // getPayQrcode() {
    //   this.getQrcodeParams = {
    //     id: this.form.id,
    //     deal_type: 2
    //   }
    //   this.payQrcodeVisible = true
    // },
    handleExport() {
      exportWmsAgent({ id: this.form.id }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    getPayQrcode() {
      // this.getQrcodeParams = {
      //   id: this.form.id,
      //   deal_type: 2
      // }
      // this.payQrcodeVisible = true
      this.cashRecordVisible = true
      this.getCashRecord()
    },
    getCashRecord() {
      this.cashRecordLoading = true
      billCashRecord({
        id: this.form.id,
        billType: 2
      }).then(res => {
        this.cashRecord = res
      }).finally(_ => {
        this.cashRecordLoading = false
      })
    },
    delCashRecord(item) {
      delCashDeal({
        cashId: item.id
      }).then(res => {
        if (res) {
          this.getCashRecord()
          this.getSellOtherInfo(this.form.id)
        }
      })
    },
    handleRefundCashRecord(item) {
      refundCashDeal({
        cashId: item.id
      }).then(res => {
        if (res) {
          this.getCashRecord()
          this.getSellOtherInfo(this.form.id)
        }
      })
    },
    createCashRecord() {
      createCashQRCode({
        id: this.form.id,
        billType: 2,
        dealType: this.cashType
      }).then(res => {
        if (res) {
          this.getCashRecord()
          this.getSellOtherInfo(this.form.id)
        }
      })
    },
    handleSubmit() {
      this.confirmLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.courierInfoData.forEach(item => {
            if (item.send_good_date) {
              item.send_good_date = moment(item.send_good_date).valueOf()
            }
          })
          const agentInfo = {
            agent: {
              ...this.form,
              agent_date: this.form.agent_date ? moment(this.form.agent_date).valueOf() : null,
              latest_delivery_time: this.form.latest_delivery_time ? moment(this.form.latest_delivery_time).valueOf() : null,
              courier_info_list: this.courierInfoData
            },
            good_agent_list: this.goodsData
          }
          createAgent(agentInfo).then(v => {
            this.formVisible = false
            this.confirmLoading = false
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            })
            // this.$router.push({
            //   name: 'WmsAgent',
            //   query: {
            //     isEdit: this.isEdit
            //   }
            // })
            this.getSellOtherInfo(v);
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}
</style>
